import React, { useState } from "react";
//import Badge from "../Badge/Badge";
import cardData from "../../cardData";
import ProjectCards from "../Cards/ProjectCards";
import { LayoutGrid, Building2, GraduationCap } from 'lucide-react';
import Button from "../Button";
import {motion} from 'framer-motion';
import { useNavigate } from "react-router-dom";
import "../components.css";
import optumLogo from '../Images/optum-logo-white.png';
import uwLogo from '../Images/uw-logo-white.png';
import tcsLogo from '../Images/tcs-logo-white.png';
import profilePhoto from '../Images/profile-photo.png';
// import skill1 from '../Images/skill-1.png';
// import skill2 from '../Images/skill-2.png';
// import skill3 from '../Images/skill-3.png';
// import skill4 from '../Images/skill-4.png';
// import skill5 from '../Images/skill-5.png';
// import skill6 from '../Images/skill-6.png';
import svgSkill1 from '../Images/svg-skill-1.svg';
import svgSkill2 from '../Images/svg-skill-2.svg';
import svgSkill3 from '../Images/svg-skill-3.svg';
import svgSkill4 from '../Images/svg-skill-4.svg';
import svgSkill5 from '../Images/svg-skill-5.svg';
import svgSkill6 from '../Images/svg-skill-6.svg';

const Work = () => {
    const[showModal, setShowModal] = useState(false);
    const[selectedProject,setSelectedProject] = useState(false);
    const[password, setPassword] = useState("");
    const[error, setError] =useState("");
    const navigate = useNavigate();
    //Hardcoded password
    const correctPassword = "@Recruiter2024";

    //Modal logic
    const openModal = (projectId) => {
        setSelectedProject(projectId);
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
        setError("");
        setPassword("");
    }

    const handleSubmit = () => {
        if(password === correctPassword){
            //Proceed with accessing new project
            //For now, let's just close the modal
            navigate(`/project/${selectedProject}`);
            closeModal();
        } else {
            setError("Please enter correct password");
        }
    }

    const isActiveTab = (category) => selectedCategory === category;
    const [selectedCategory, setSelectedCategory] = useState('professional');
    const projectCards = cardData.filter(card =>{
        if(selectedCategory==='all'){
            return true;
        }
        return card.category === selectedCategory;
    }).map(x => {
        return (<ProjectCards
            key={x.id}
            //projectData={x}
            category={x.category}
            id={x.id}
            image={x.image}
            panelSubheading={x.panelSubheading}
            cardHeading={x.heading}
            cardSubheading={x.subheading}
            badgesArray={x.badges}
            secureStatus={x.secure}
            openModal={openModal}
            onClick={() => {
                if(x.secure){
                    openModal(x.id);
                }
            }}
      />)})

        return(<div>
                    {/* Background gradient */}
                    <motion.div 
                    initial={{  opacity: 0 }}
                    animate={{  opacity: 1 }}
                    transition={{  ease:"easeOut", duration:3 }}
                    class="w-full h-72 blur-3xl relative top-40 z-0 bg-gradient-to-r  from-amber-400/40 via-rose-500/40 to-orange-400/40  dark:bg-gradient-to-r dark:from-rose-500/60 dark:via-purple-600/60 dark:to-cyan-400/60 ">
                    </motion.div>

                    {/*Hero content */}
                    <motion.div 
                        initial={{ y:+50, opacity: 0 }}
                        animate={{ y:0, opacity: 1 }}
                        transition={{  ease:"easeOut", duration:0.5 }}
                        
                        class="relative -mt-48">
                            <div class="container">
                            
                                <div class="absolute w-1 h-full rounded-md mr-4 bg-gradient-to-b  from-amber-400/40 via-rose-500/40 to-orange-400/40 dark:from-rose-500 dark:via-purple-600 dark:to-cyan-400"/>
                                <img class="relative w-20 h-20 rounded-full ml-6 mb-6 border-4 border-amber-600/50 dark:border-cyan-500" src={profilePhoto} width="auto" height="auto" alt="brand-logo" />
                                <div class="ml-6">
                                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-gray-800  dark:text-gray-300">DESIGN X CODE</h6>
                                    <h3 class="text-2xl md:text-3xl font-bold text-gray-700 tracking-normal  dark:text-gray-300 leading-normal my-4">
                                    I infuse design into usable interfaces and believe that<br/>impactful design excels in the subtle interaction<br/>between people, culture, and technology.
                                    </h3>
                                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-gray-800  dark:text-gray-300">BRANDS AND IMPACT</h6>
                                    <div class="mt-4 mb-12">
                                        <img src={optumLogo} width="auto" height="auto" alt="brand-logo" className="brand-logo" />
                                        <img src={uwLogo} width="auto" height="auto" alt="brand-logo" className="brand-logo"/>
                                        <img src={tcsLogo} width="auto" height="auto" alt="brand-logo" className="brand-logo"/>
                                    </div>
                                    <div class="grid grid-cols-3 grid-rows-2 gap-4 w-full h-full">
                                        {[
                                        [svgSkill1,'Human-Computer Interaction'],
                                        [svgSkill2,'Systems thinking'],
                                        [svgSkill3,'Interaction Design'],
                                        [svgSkill4,'Design Systems'],
                                        [svgSkill5,'Designer-Developer Collaboration'],
                                        [svgSkill6,'Design Technologist'],
                                        ].map(([image,title])=>(
                                        
                                        <div class="flex border border-gray-700 p-4 items-center relative rounded-xl bg-stone-300/80 dark:bg-gray-800"> 
                                            <img src={image} class="-scale-75 rotate-180 mr-2 
                                            [filter:brightness(0)_saturate(0)_opacity(0.5)] dark:brightness-0 dark:invert
                                            " alt="skills"></img>
                                            <p class="text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">{title}</p>
                                        </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                    </motion.div>
                                        
                    {/* Card Tabs */}
                    <motion.div
                        initial={{ y:+50, opacity: 0 }}
                        animate={{ y:0, opacity: 1 }}
                        transition={{  ease:"easeOut", duration:0.5 }}
                        class="container">
                        <div class="flex flex-row gap-2 mb-4 mt-12 pb-2 border-b border-solid border-slate-500 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200">
                            <button 
                                class={`flex rounded-lg p-3 pr-10 pl-10  transition-all duration-200 ${
                                    isActiveTab('all') 
                                        ? 'bg-stone-400 dark:bg-gray-800 text-white dark:text-white' 
                                        : 'text-gray-500 dark:text-gray-300 hover:bg-stone-300 dark:hover:bg-gray-800'
                                }`}
                                onClick={() => setSelectedCategory('all')}
                            >
                                <LayoutGrid/>
                                <h6 class="text-lg md:text-base h-0 ml-2">
                                    All categories
                                </h6>
                            </button>
                            
                            <button 
                                class={`flex rounded-lg p-3 pr-10 pl-10  transition-all duration-200 ${
                                    isActiveTab('professional') 
                                        ? 'bg-stone-400 dark:bg-gray-800 text-white dark:text-white' 
                                        : 'text-gray-500 dark:text-gray-300 hover:bg-stone-300 dark:hover:bg-gray-800'
                                }`}
                                onClick={() => setSelectedCategory('professional')}
                            >
                                <Building2/>
                                <h6 class="text-lg md:text-base h-0 ml-2">
                                    Professional work
                                </h6>
                            </button>
                            
                            <button 
                                class={`flex rounded-lg p-3 pr-10 pl-10  transition-all duration-200 ${
                                    isActiveTab('academic') 
                                        ? 'bg-stone-400 dark:bg-gray-800 text-white dark:text-white' 
                                        : 'text-gray-500 dark:text-gray-300 hover:bg-stone-300 dark:hover:bg-gray-800'
                                }`}
                                onClick={() => setSelectedCategory('academic')}
                            >
                                <GraduationCap/>
                                <h6 class="text-lg md:text-base h-0 ml-2">
                                    Academic work
                                </h6>
                            </button>
                        </div>
                    </motion.div>                  

                    {/* Project Cards */}
                    <motion.div
                        initial={{ y:+50, opacity: 0 }}
                        animate={{ y:0, opacity: 1 }}
                        transition={{  ease:"easeOut", duration:0.5 }}
                    class="mt-0">
                    {projectCards}
                    </motion.div>

                    {/* Modal Content  */}
                    { selectedProject && showModal &&
                        <div class="flex justify-center items-center fixed top-0 left-0 w-full h-full bg-slate-900 z-[999]">
                            <div class="p-6 rounded-lg max-w-[400px] w-full shadow-2xl bg-slate-800 shadow-slate-950 relative">
                                <span class="absolute top-4 right-4 cursor-pointer" onClick={closeModal}>&times;</span>
                                <h4>Password protected</h4>
                                <p>This project is password protected, please enter password</p>
                                
                                <div>
                                    <div className="grid grid-rows-1 grid-cols-3 gap-x-2">
                                        <input class="col-span-2 bg-slate-900" type="password" text="password" value={password} onChange={(e)=> setPassword(e.target.value)} placeholder="Enter password" />
                                        <Button size="sm" label="Submit" onClick={handleSubmit}/>    
                                    </div>
                                </div>
                                {/* <button onClick={handleSubmit}>Submit</button> */}
                                {error && <p className="mt-2 text-white">{error}</p>}
                            </div>
                        </div>
                    }
            </div>
        )       
    };  

    export default Work;