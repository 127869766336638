import './App.css';
import './index.css';
import Nav from './components/Navigation/Nav';
import Work from './components/Work/Work';
import Gimmick from './components/Gimmick/Gimmick';
import Resume from './components/Resume/Resume';
import Aboutme from './components/Aboutme/Aboutme';
import Dev from './components/Devmode/Dev';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState,useEffect } from 'react';
import Smartsales from './components/Project/Smartsales';
import Optum from './components/Project/Optum';
import Paceport from './components/Project/Paceport';
import Marysplace from './components/Project/Marysplace';
import Vocalamp from './components/Project/Vocalamp';
import WackAMole from './components/Project/WackAMole';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/ScrollToTop';
import ScrollToTopButton from './components/ScrollToTopButton';
//import Decorator from './components/Decorators';
import { motion, useScroll, useSpring } from 'framer-motion';
//import HeroGradient from './components/HeroGradient';

//import { useEffect } from 'react';

function App() {
    
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Check if there's a stored preference
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode !== null) {
        return JSON.parse(savedMode);
    }
    // If no stored preference, check system preference
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
});

useEffect(() => {
    // Update class and save preference whenever isDarkMode changes
    if (isDarkMode) {
        document.body.classList.add("dark");
    } else {
        document.body.classList.remove("dark");
    }
    localStorage.setItem('darkMode', JSON.stringify(isDarkMode));
}, [isDarkMode]);

useEffect(() => {
    // Listen for system preference changes
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    
    const handleChange = (e) => {
        // Only update if there's no stored preference
        if (localStorage.getItem('darkMode') === null) {
            setIsDarkMode(e.matches);
        }
    };

    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
}, []); // Empty dependency array as this only needs to run once

  
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 25,
    restDelta: 0.001
  });

  return (
    <div class="p-6 bg-stone-200 transition-colors duration-500 dark:bg-gray-950">
      {/* Progress Indicator */}
      <motion.div className="fixed top-0 left-0 right-0 h-1 rounded opacity-80 bg-gradient-to-r from-gray-800 via-gray-700 to-gray-300 origin-left z-[1000000]" style={{ scaleX }} />
      
      {/* <HeroGradient /> */}
      {/* <Decorator /> */}
      <ScrollToTopButton />
      <Router basename="/">
        <ScrollToTop />
        <Nav isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode}/>
        <Routes>
          <Route path="/" element={<Work />} />
          <Route path="/gimmick" element={<Gimmick />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/aboutme" element={<Aboutme />} />
          <Route path="/dev" element={<Dev />} />
          <Route path="/project">
            <Route path="/project/optum" element={<Optum />} />
            <Route path="/project/smartsales" element={<Smartsales />} />
            <Route path="/project/paceport" element={<Paceport />} />
            <Route path="/project/marysplace" element={<Marysplace />} />
            <Route path="/project/vocalamp" element={<Vocalamp />} />
            <Route path="/project/whackamole" element={<WackAMole />} />
          </Route>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
